<template>
    <div class="modal fade show" id="guestconfirmsendemailmodal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-modal="true" style="display: none">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t("common_confirm") }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p class="sendEmailQuestion">{{ $t("siteusers_guestConfirmEmailL1") }}</p>
                    <p class="sendEmailInfo">({{ $t("siteusers_guestConfirmEmailL2") }})</p>
                </div>
                <div class="modal-footer">
                    <button id="confirmSendEmailNoButton" type="button" @click="onCancelButton" class="btn btn-secondary" data-dismiss="modal">{{ $t("common_no") }}</button>
                    <button id="confirmSendEmailYesButton" type="button" @click="onConfirmButton" data-dismiss="modal" class="btn btn-brand">{{ $t("common_yes") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["pEmail"],
    data() {
        return {};
    },
    created: function() {
        console.log("Component(guestConfirmSendEmailModal)::created() - called");
    },
    mounted: function() {
        console.log("Component(guestConfirmSendEmailModal)::mounted() - Init metronic layout");
        KTLayout.init();
    },
    destroyed: function() {
        console.log("Component(guestConfirmSendEmailModal)::destroyed() - called");
    },
    methods: {
        onConfirmButton() {
            console.log("Component(guestConfirmSendEmailModal)::onConfirmButton() - called");
            this.$emit("confirmsendemailyes");
        },
        onCancelButton() {
            console.log("Component(guestConfirmSendEmailModal)::onCancelButton() - called");
            this.$emit("confirmsendemailno");
        },
        display() {
            console.log("Component(guestConfirmSendEmailModal)::display() - called");
            $("#guestconfirmsendemailmodal").modal("show");
        }
    }
};
</script>

<style scoped>
.sendEmailQuestion {
    color: #6c7293;
    font-weight: 400;
    margin-bottom: 0px;
}
.sendEmailInfo {
    color: #c72f5f;
    font-weight: 400;
}
</style>
