<template>
    <div id="editGuestAccessPage" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="editGuest"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="siteusers_editGuest"></app-sitetitle>
                        </div>
                        <!-- end:: Content Head -->

                        <!-- begin:: Content -->
                        <div class="kt-content kt-grid__item kt-grid__item--fluid">
                            <!-- begin:: Content -->

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="kt-portlet">
                                        <div class="kt-portlet__head">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <polygon id="Shape" points="0 0 24 0 24 24 0 24" />
                                                            <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" id="Mask" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                                            <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" id="Mask-Copy" fill="#000000" fill-rule="nonzero" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("siteusers_editGuest") }}
                                                </h3>
                                            </div>
                                        </div>

                                        <!--begin::Form-->
                                        <form class="kt-form" novalidate="novalidate">
                                            <div class="kt-portlet__body">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group validated">
                                                            <label for="editGuestAccessForm_firstnameInput">{{ $t("user_firstname") }} *</label>
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><i class="la la-pencil"></i></span>
                                                                </div>
                                                                <input v-model="vFirstname" @input="$v.vFirstname.$touch()" @keyup="onChangeForm" type="text" class="form-control" id="editGuestAccessForm_nameInput" :placeholder="$t('siteusers_guestFirstnamePlaceholder')" disabled="disabled" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="form-group validated">
                                                            <label for="editGuestAccessForm_lastnameInput">{{ $t("user_lastname") }} *</label>
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><i class="la la-pencil"></i></span>
                                                                </div>
                                                                <input v-model="vLastname" @input="$v.vLastname.$touch()" @keyup="onChangeForm" type="text" class="form-control" id="editGuestAccessForm_nameInput" :placeholder="$t('siteusers_guestLastnamePlaceholder')" disabled="disabled" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group validated">
                                                            <label for="editGuestAccessForm_nameInput">{{ $t("siteusers_emailLabel") }} *</label>
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><i class="la la-envelope"></i></span>
                                                                </div>
                                                                <input v-model="vEmail" @input="$v.vEmail.$touch()" @keyup="onChangeForm" type="text" class="form-control" id="editGuestAccessForm_emailInput" :placeholder="$t('siteusers_guestEmailPlaceholder')" disabled="disabled" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- <div class="col-md-2">
                                                        <label class="marginB20"> </label>
                                                        <div class="kt-form__actions">
                                                            <button id="editGuestAccessEmailButton" @click="onSendEmailButton" type="button" class="btn btn-brand">
                                                                <i class="la la-refresh"></i>
                                                                {{ $t("common_sendEmailAgain") }}
                                                            </button>
                                                        </div>
                                                    </div> -->

                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="guestExpirationDatePicker">{{ $t("common_expirationDateTime") }} *</label>
                                                            <div class="input-group date">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><i class="la la-calendar"></i></span>
                                                                </div>
                                                                <input type="text" class="form-control" id="guestExpirationDatePicker" readonly="" :placeholder="$t('common_selectDateTime')" />
                                                            </div>
                                                            <span class="form-text text-muted">{{ $t("siteusers_guestExpirationDateExample") }}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group validated">
                                                    <label class="marginB20">{{ $t("siteusers_guestAssignRoleText") }}</label>
                                                    <div class="kt-radio-list">
                                                        <label v-for="role in vRolesList" :key="role.id" class="kt-radio kt-radio--bold kt-radio--brand">
                                                            <input type="radio" :id="'roleRadio_' + role.name" name="role" :value="role.id" v-model="vRoleId" @change="onChangeForm" checked="checked" /> {{ role.isDefault === true ? $t("ROLE_" + role.name) : role.name }} <br />
                                                            <div class="featuresList">
                                                                (
                                                                <span v-for="(feature, index) in role.features" :key="feature.id">{{ $t("ROLE_FEATURES_" + feature.name) }}<span v-if="index + 1 < role.features.length">, </span></span>
                                                                )
                                                            </div>
                                                            <span></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="kt-portlet__foot">
                                                <div class="kt-form__actions kt-form__actions--right">
                                                    <button id="editGuestAccessCreateButton" @click="onUpdateButton" type="button" class="btn btn-brand kt-margin-r-5" :disabled="$v.$invalid || !isFormDataChanged">
                                                        <i class="la la-save"></i>
                                                        {{ $t("common_saveChanges") }}
                                                    </button>
                                                    <button id="editGuestAccessCancelButton" @click="onCancelButton" type="button" class="btn btn-secondary">
                                                        {{ $t("common_cancel") }}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                        <!--end::Form-->
                                    </div>
                                </div>
                            </div>

                            <!-- end:: Content -->
                        </div>
                        <!-- end:: Content -->
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                    <!-- begin:: Modal to confirm leave page -->
                    <app-confirmleavepagemodal ref="confirmleavepagemodalref"></app-confirmleavepagemodal>
                    <!-- end::Modal -->
                    <!-- begin:: Modal to confirm send email -->
                    <app-confirmsendemailmodalref ref="confirmsendemailmodalref" v-bind:pEmail="vEmail" @confirmsendemailyes="onConfirmSendEmail" @confirmsendemailno="onCancelSendEmail"></app-confirmsendemailmodalref>
                    <!-- end::Modal -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SiteTitle from "../site/widgets/sitetitle.vue";
import commonVueHelper from "../../helpers/commonVueHelper";
import confirmSendEmailModal from "../modals/guestconfirmsendemailmodal.vue";
import moment from 'moment-timezone';

export default {
    data() {
        return {
            vLastname: "",
            vFirstname: "",
            vEmail: "",
            vRoleId: "",
            vRolesList: [],
            vDate: null,
            startDate: null,
            isFormDataChanged: false
        };
    },
    created: function() {
        console.log("Component(EditGuest)::created() - called");
        this.getSiteRoles(this.$route.params.siteId);
        // Get current guest access
        this.getGuestById({
            siteId: this.$route.params.siteId,
            guestId: this.$route.params.guestId
        });
    },
    mounted: function() {
        console.log("Component(EditGuest)::mounted() - Init metronic layout");
        KTLayout.init();
        this.initExpirationDateTimePicker();
    },
    beforeDestroy: function() {
        console.log("Component(EditGuest)::beforeDestroy() - called");
        // Reset component data
        this.vLastname = null;
        this.vFirstname = null;
        this.vEmail = null;
        this.vRoleId = null;
        this.vRolesList = null;
        this.vDate = null;
        // Reset store
        this.resetSiteGuestsState();
        this.resetSiteRolesState();
    },
    destroyed: function() {
        console.log("Component(EditGuest)::destroyed() - called");
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        currentGuest(guest) {
            console.log("Component(EditAsset)::watch(currentGuest) - called with ", guest);
            if (guest) {
                this.vFirstname = guest.user.firstName;
                this.vLastname = guest.user.lastName;
                this.vEmail = guest.user.loginEmail;
                this.vRoleId = guest.role.id;
                this.vDate = guest.expiredAccessAt;
                let guestDate = new Date(this.vDate);
                if (!this.startDate) {
                    this.startDate = commonVueHelper.getToDayPlusXmin(30);
                }
                this.setExpirationDateTimePicker(guestDate, false);
            }
        },

        siteRoles(roles) {
            console.log("Component(EditAsset)::watch(siteRoles) - called with ", roles);
            if (roles) {
                this.vRolesList = _.orderBy(roles, [role => role.name.toLowerCase()], ["asc"]);
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["currentGuest", "siteRoles"])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["getSiteRoles", "getGuestById", "updateGuestOfSite", "reInviteToGuest", "resetSiteGuestsState", "resetSiteRolesState"]),

        initExpirationDateTimePicker() {
            let selector = $("#guestExpirationDatePicker");
            if (!this.startDate) {
                this.startDate = commonVueHelper.getToDayPlusXmin(30);
            }
            commonVueHelper.initDateTimePicker(selector, "top-right", null, this.startDate);
            selector.on("changeDate", event => {
                let date = null;
                if (event.date) {
                    date = commonVueHelper.datetimeToISOString(event.date.valueOf());
                } else if (event.target.value) {
                    date = commonVueHelper.datetimeToISOString(new Date(event.target.value));
                }
                if (date && date != this.vDate) {
                    this.vDate = date;
                    this.onChangeForm(event);
                }
            });
        },

        setExpirationDateTimePicker(date, updatedDate) {
            let selector = $("#guestExpirationDatePicker");
            commonVueHelper.setDateTimePickerVal(selector, date);
            if (updatedDate) {
                selector.trigger({ type: "changeDate" });
            }
        },

        onChangeForm(event) {
            console.log("Component(EditGuest)::onChangeForm() - called", event);
            this.isFormDataChanged = true;
        },

        // Function called when user click on the "Cancel" button
        onCancelButton() {
            console.log("Component(EditGuest)::onCancelButton() - called");
            this.$router.push({ name: "listSiteGuests", params: { fromAction: "cancelButton" } });
        },

        onUpdateButton() {
            console.log("Component(EditGuest)::onUpdateButton() - called");
            const data = {
                siteId: this.$route.params.siteId,
                guestId: this.$route.params.guestId,
                roleId: this.vRoleId,
                expiredAt: this.vDate
            };
            this.updateGuestOfSite(data);
        },

        onSendEmailButton() {
            console.log("Component(EditGuest)::onSendEmailButton() - called");
            $("#guestconfirmsendemailmodal").modal("show");
        },

        onConfirmSendEmail() {
            console.log("Component(EditGuest)::onConfirmSendEmail() - called");
            this.reInviteToGuest({ guestId: this.$route.params.guestId, siteId: this.$route.params.siteId, timezone: moment.tz.guess() });
        },

        onCancelSendEmail() {
            console.log("Component(EditGuest)::onCancelSendEmail() - called");
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-sitetitle": SiteTitle,
        "app-confirmsendemailmodalref": confirmSendEmailModal
    },
    beforeRouteLeave(to, from, next) {
        // -- Called when the route that renders this component is about to be navigated away from.
        commonVueHelper.displayConfirmModalOnLeavePage(this, "EditGuest", "update", this.isFormDataChanged, to, from, next);
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
.featuresList {
    font-size: 12px;
    color: black;
}
</style>
